.home {
    width: 100vw;
    height: 100vh;
    background: #E81F76;
    color: #fff;
    line-height: 1.2;
}

.home-inner {
    position: absolute;
    left: 50%;
    top: calc(50% + 25px);
    width: 100%;
    height: auto;
    transform: translate(-50%, -50%);
    padding: 0 30px;
    max-width: 600px;
}

@media screen and (min-width: 1145px) {
    .home-inner {
        max-width: 1145px;
    }
}

.home-all-text {
    position: relative;
    margin: 0 0 40px;
}

@media screen and (min-width: 1145px) {
    .home-all-text {
        margin: 0 0 50px;
    }
}

.home-text {
    font-size: 4.6rem;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    opacity: 0;
    font-weight: 700;
}

@media screen and (min-width: 768px) {
    .home-text {
        font-size: 6rem;
    }
}

@media screen and (min-width: 1145px) {
    .home-text {
        font-size: 10.8rem;
    }
}

.home-text .word {
    display: inline-block;
}

.home-text .letter {
    opacity: 0;
    transition: all .5s;
    transform-style: preserve-3d;
    transform-origin: center center;
    display: inline-block;
    position: relative;
}

.home-text .letter.show {
    opacity: 1;
}

.home-text.last {
    font-size: 2.8rem;
    position: relative;
    transition: opacity 1.5s;
    font-weight: 400;
    line-height: 1.3;
}

@media screen and (min-width: 768px) {
    .home-text.last {
        font-size: 3.6rem;
    }
}

@media screen and (min-width: 1145px) {
    .home-text.last {
        font-size: 6.6rem;
    }
}

.home-text.last.show {
    opacity: 1;
}

.home-cta-wrap {
    position: relative;
}

.home-cta {
    font-size: 2.2rem;
    border-bottom: 1px dotted #fff;
    padding: 0 0 .1em;
    cursor: pointer;
    opacity: 0;
    pointer-events: none;
    transition: opacity 1s;
}

@media screen and (min-width: 768px) {
    .home-cta {
        font-size: 3.5rem;
    }
}

.home-cta-wrap.switch .home-cta {
    opacity: 1;
    pointer-events: auto;
}

.home-cta img {
    display: inline-block;
    width: 12px;
    margin: 0 0 0 10px;
}

@media screen and (min-width: 768px) {
    .home-cta img {
        width: 18px;
    }
}

.home-logo {
    position: absolute;
    left: 0;
    top: -10px;
    width: auto;
    height: 42px;
    transition: opacity 1s;
}

.home-cta-wrap.switch .home-logo {
    opacity: 0;
    pointer-events: none;
}

@media screen and (min-width: 1145px) {
    .home-logo {
        height: 60px;
    }
}

.home-wirehive {
    position: fixed;
    left: 30px;
    top: -4px;
}

.home-wirehive img {
    display: block;
    width: 94px;
    height: auto;
}

@media screen and (min-width: 1145px) {
    .home-wirehive {
        left: 60px;
        top: -8px;
    }

    .home-wirehive img {
        width: 141px;
    }
}