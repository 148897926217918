.workshop-form-success {
	text-align: center;
	font-size: 3rem;
	margin: 3em 0;
	font-weight: 700;
	color: #E81F76;
}

.workshop-form {
	margin: 3em 0;
}

.workshop-form button[type=submit] {
	background: #E81F76;
}

.workshop-form button[type=submit]:hover {
	background: #bb2365;
}

.workshop-form .input-wrap.required::after {
	content: 'required';
	position: absolute;
	right: 20px;
	bottom: 22px;
	font-size: 1.4rem;
	color: #E62156;
}

.workshop-form label {
	margin: 0 0 4px;
}
