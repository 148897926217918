.full-swipe-body {
    overflow: hidden;
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
}

.full-swipe {
    position: relative;
    width: 100vw;
    height: auto;
    transition: all .5s;
}

.full-swipe.dragging {
    transition: none;
}

.full-swipe > * {
    width: 100%;
    height: 100vh;
}
