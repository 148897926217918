.project {
    background: #F4F4F4;
    color: #000;
    width: 100vw;
    height: 100vh;
    overflow: auto;
}

.project a {
    color: #000;
}

.project h1 {
    margin: 0 0 .2em;
}

.project__type {
    font-size: 1.6rem;
    margin: 0 0 .1em;
}

@media screen and (min-width: 768px) {
    .project__type {
        font-size: 2.7rem;
        margin: 0 0 .3em;
    }
}

.project__role {
    font-size: 1.2rem;
    color: #4C4C4C;
    margin: 0 0 1em;
}

@media screen and (min-width: 768px) {
    .project__role {
        font-size: 1.4rem;
        margin: 0 0 1.5em;
    }
}

.project__link {
    text-align: center;
    font-size: 2rem;
    margin: 2em 0 6em;
}

@media screen and (min-width: 768px) {
    .project__link {
        font-size: 3rem;
        margin: 2em 0 0;
    }
}

.project__link a {
    color: #E81F76;
    text-decoration: none;
    display: inline-block;
    border-bottom: 1px dotted #E81F76;
    padding: 0 0 .2em;
}
