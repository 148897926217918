.nav {
    position: fixed;
    right: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    z-index: 99;
}

.nav--closed {
    pointer-events: none;
}

.nav__menu {
    display: none;
    width: 100%;
    height: 100%;
}

.nav--open .nav__menu {
    display: block;
}

.nav a {
    color: #fff;
    text-decoration: none;
}

.nav__bg {
    position: absolute;
    right: 0;
    top: 0;
    background: linear-gradient(to bottom left, #656565 0%, #1D1D1D 100%);
    transition: all .3s ease-out;
}

.nav--closed .nav__bg {
    width: 300px;
    height: 300px;
    transform: rotate(15deg) translate(145px, -235px);
    transform-origin: center center;
    transition: all .2s;
}

@media screen and (min-width: 1024px) {
    .nav--closed .nav__bg {
        width: 600px;
        height: 600px;
        transform: rotate(15deg) translate(350px, -503px);
    }
}

@media screen and (min-width: 1024px) {
    .nav--closed.nav--show-peak .nav__bg {
        transform: rotate(7deg) translate(372px, -479px);
    }
}

.nav--open .nav__bg {
    transform: none;
    width: 100%;
    height: 100%;
}


.nav__btn {
    position: fixed;
    top: 0;
    right: 0;
    color: #fff;
    font-size: 1.7rem;
    padding: 2em;
    cursor: pointer;
    transition: all .2s;
    pointer-events: auto;
}

@media screen and (min-width: 1024px) {
    .nav__btn {
        font-size: 2.5rem;
    }
}

@media screen and (min-width: 1024px) {
    .nav--closed.nav--show-peak .nav__btn {
        transform: scale(1.1);
        letter-spacing: .5px;
    }
}

.nav--open .nav__btn {
    opacity: 0;
    pointer-events: none;
}

.nav__inner {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

@media screen and (orientation: landscape) and (max-width: 767px) {
    .nav__inner {
        display: block;
        overflow: auto;
        -webkit-overflow-scrolling: touch;
        padding: 20px 0;
    }
}

.nav__main {
    list-style-type: none;
    margin: 0;
    padding: 0;
    font-size: 2.4rem;
}

@media screen and (min-width: 768px) {
    .nav__main {
        font-size: 3rem;
    }
}

.nav__main-item {
    margin: .7em 0;
    opacity: 0;
    transform: translate(0, 35px);
    transform-origin: center center;
}

.nav__main-item:first-child {
    margin-top: 0;
}

.nav__extra {
    position: relative;
    list-style-type: none;
    margin: 0;
    padding: .8em 0;
    font-size: 1.5rem;
}

.nav__extra-item {
    margin: .7em 0;
    opacity: 0;
}

.nav__extra::before,
.nav__extra::after {
    content: '';
    position: absolute;
    left: 50%;
    top: 0;
    transform: translate(-50%, 0);
    width: 42px;
    height: 1px;
    background: #000;
}

.nav__extra::after {
    top: auto;
    bottom: 0;
}

.nav__close {
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
}

.nav-anim-start {
    opacity: 0;
}

.nav-anim-end {
    opacity: 1;
    transform: translate(0, 0) scale(1) rotateY(0);
}
