.contact-details {
    position: absolute;
    left: 20px;
    top: 80px;
    color: #fff;
    font-size: 1.4rem;
}

@media screen and (min-width: 1024px) {
    .contact-details {
        left: 60px;
        top: 140px;
        font-size: 2rem;
    }
}

.contact-details a {
    color: #fff;
    text-decoration: none;
}

.contact-details p {
    margin: 0 0 .7em;
}

.contact-details__phone,
.contact-details__email {
    font-size: 1.8rem;
}

@media screen and (min-width: 1024px) {
    .contact-details__phone,
    .contact-details__email {
        font-size: 3.1rem;
    }
}

.contact-marker img {
    width: 97px;
    height: 116px;
    transform: translate(-25%, calc(-100% + 15px));
}
