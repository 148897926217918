.sprint-columns {
	text-align: center;
	font-size: 1.4rem;
	margin: 4em 0;
}

@media screen and (min-width: 1024px) {
	.sprint-columns {
		display: flex;
		justify-content: space-between;
	}
}

.sprint-column {
	background: #fff;
	border-radius: 8px;
	overflow: hidden;
	margin: 0 auto 1em;
	box-shadow: 0 3px 5px rgba(0, 0, 0, .4);
	max-width: 400px;
}

@media screen and (min-width: 1024px) {
	.sprint-column {
		width: calc(33.333% - 20px);
	}
}

.sprint-column__head {
	background: #DDE4E5;
	color: #E81F76;
	font-size: 2.1rem;
	font-weight: 700;
	padding: .9em 0;
}

.sprint-column__main {
	padding: 1.4em 1em;
}

.sprint-column__list {
	margin: 0;
	padding: 0;
	color: #000;
}

.sprint-column__intro {
	color: #303030;
}

.sprint-column__arrow {
	width: 14px;
	height: auto;
	display: block;
	margin: 1em auto !important;
}
