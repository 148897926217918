.team-member {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    color: #fff;
    background: #E81F76;
}

@media screen and (min-width: 768px) {
    .team-member {
        /* align-items: center; */
    }
}

@media screen and (min-width: 1024px) {
    .team-member {
        align-items: center;
    }
}

.team-member__inner {
    opacity: 0;
    transition: opacity 2s;
    transition-delay: .5s;
    transform: translate(0, -76px);
}

@media screen and (min-width: 768px) {
    .team-member__inner {
        transform: translate(0, -100px);
        max-width: calc(100% - 120px);
    }
}


@media screen and (min-width: 768px) and (max-width: 1023px) and (max-height: 850px) {
    .team-member__inner {
        transform: translate(0, -20px);
    }
}

@media screen and (min-width: 1024px) {
    .team-member__inner {
        transform: translate(73%, 0);
        max-width: 520px;
        width: 34vw;
    }
}

@media screen and (min-width: 1170px) {
    .team-member__inner {
        transform: translate(73%, 0);
    }
}

.team-member.visible .team-member__inner {
    opacity: 1;
}

.team-member__inner h2 {
    font-size: 2.6rem;
    margin: 0 0 .3em;
    text-align: center;
}

@media screen and (min-width: 768px) {
    .team-member__inner h2 {
        font-size: 4.2rem;
        text-align: left;
    }
}

.team-member__inner h3 {
    font-size: 1.7rem;
    margin: 0 0 1.4em;
    text-align: center;
}

@media screen and (min-width: 768px) {
    .team-member__inner h3 {
        font-size: 2.2rem;
        text-align: left;
    }
}

.team-member__desc {
    font-size: 1.6rem;
    line-height: 1.65;
    display: none;
}

@media screen and (min-width: 768px) {
    .team-member__desc {
        display: block;
        font-size: 1.5rem;
    }
}

@media screen and (min-width: 1300px) {
    .team-member__desc {
        font-size: 1.7rem;
    }
}

.team-member__img-wrap {
    position: relative;
    padding: 0 20px;
}

.team-member__img {
    max-width: 100%;
    margin: 0 auto;
}

.team-gfx__spot {
    position: absolute;
    width: 20px;
    height: 20px;
    border: 2px solid #fff;
    border-radius: 50%;
    opacity: 0;
    pointer-events: auto;
    transition: opacity .5s;
}

@media screen and (min-width: 768px) {
    .team-gfx__spot {
        display: none;
    }
}

.team-gfx__spot.active { opacity: 1; }

.team-gfx__spot--1 {
    right: 45px;
    top: 93px;
}

.team-gfx__spot--2 {
    left: 40px;
    top: 70px;
}

.team-gfx__spot--3 {
    left: 42px;
    top: 140px;
}

.team-gfx__spot--4 {
    right: 42px;
    top: 260px;
}

.team-gfx__spot::before,
.team-gfx__spot::after {
    content: '';
    position: absolute;
    width: 2px;
    height: 9px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
}

.team-gfx__spot::after {
    transform: translate(-50%, -50%) rotate(90deg);
}

.team-mobile-bg {
    position: fixed;
    top: 0;
    right: 0;
    border-radius: 50%;
    height: 0;
    width: 0;
    transform: translate(50%, -50%);
    background: #fff;
    z-index: 999;
    transition: all .4s;
}

.team-mobile-bg.open {
    height: 180vh;
    width: 180vh;
}

.team-mobile-bg__close {
    position: absolute;
    top: calc(50% + 15px);
    right: calc(50% + 15px);
    width: 20px;
    height: 20px;
    border: 2px solid #E81F76;
    border-radius: 50%;
    transform-origin: center center;
    transform: rotate(45deg);
    opacity: 0;
    pointer-events: none;
}

.team-mobile-bg.open .team-mobile-bg__close {
    opacity: 1;
    pointer-events: auto;
    transition: .5s opacity;
    transition-delay: .35s;
}

.team-mobile-bg__close::before,
.team-mobile-bg__close::after {
    content: '';
    position: absolute;
    width: 2px;
    height: 9px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background: #E81F76;
}

.team-mobile-bg__close::after {
    transform: translate(-50%, -50%) rotate(90deg);
}

.team-gfx {
    position: fixed;
    left: 50%;
    top: 50%;
    width: 100%;
    max-width: 500px;
    transform: translate(-50%, -58%) rotate(0);
    transform-origin: center center;
    pointer-events: none;
    opacity: 0;
    transition: .5s;
    transition-delay: .5s;
}

.team-gfx.show {
    opacity: 1;
}

@media screen and (min-width: 768px) {
    .team-gfx {
        transform: translate(-50%, -80%);
        max-width: 490px;
        top: 50%;
    }
}

@media screen and (min-width: 768px) and (max-width: 1023px) and (max-height: 850px) {
    .team-gfx {
        max-width: 400px;
        transform: translate(-50%, -79%);
    }
}

@media screen and (min-width: 1024px) {
    .team-gfx {
        transform: translate(-96%, -50%);
        max-width: 490px;
        top: 50%;
    }
}

@media screen and (min-width: 1500px) {
    .team-gfx {
        max-width: 700px;
    }
}

@media screen and (min-width: 1900px) {
    .team-gfx {
        max-width: 800px;
    }
}

@media screen and (min-width: 2500px) {
    .team-gfx {
        max-width: 1000px;
    }
}

.team-gfx__img {
    position: relative;
    width: 100%;
    height: auto;
    transition: opacity .5s ease-in-out;
    transition-delay: .35s;
}

.team-gfx__img:not(:first-child) {
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
}

.team-gfx__img:not(:first-child).active {
    opacity: 1;
}

.team-gfx__svg {
    position: absolute;
    transition: opacity .5s ease-in-out;
    transition-delay: .35s;
    opacity: 0;
}

.team-gfx__svg--1 {
    width: 70%;
    height: auto;
    left: 17%;
    top: 37.7%;
}

.team-gfx__svg--2 {
    width: 64%;
    height: auto;
    left: 41.6%;
    top: -7%;
}

.team-gfx__svg--3 {
    width: 15.2%;
    height: auto;
    right: 11.5%;
    top: 20.5%;
}

.team-gfx__svg--4 {
    width: 38%;
    height: auto;
    left: 61%;
    top: 18%;
}

.team-gfx__svg.active {
    opacity: 1;
    transition-delay: .9s;
}

/* Dave rocking animation */
.team-gfx.active-3 .team-gfx__img,
.team-gfx.active-3 .team-gfx__svg:not(.team-gfx__svg--3) {
    /* animation-duration: 4s;
    animation-name: float;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out; */
}

@keyframes float {
    0% { transform: translate(0, 0) rotate(0deg); }
    50% { transform: translate(0, 45px) rotate(7deg); }
    100% { transform: translate(0, 0) rotate(0deg); }
}

.team-scroll-icon {
    position: fixed;
    left: 50%;
    bottom: 80px;
    width: 28px;
    height: auto;
    transform: translate(-50%, 0);
    display: none;
}

@media screen and (min-width: 1200px) {
    .team-scroll-icon {
        display: block;
    }
}

.team-mobile-desc {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    opacity: 0;
    pointer-events: none;
    font-size: 1.4rem;
    color: #282828;
    padding: 70px 35px 0;
    z-index: 999;
    line-height: 1.65;
}

@media screen and (min-width: 768px) {
    .team-mobile-desc {
        display: none;
    }
}

.team-mobile-desc.active {
    opacity: 1;
    transition: opacity .5s;
    transition-delay: .35s;
}
