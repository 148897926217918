.work {
    margin: 0 auto;
}

.work-projects {

}

@media screen and (min-width: 1024px) {
    .work-projects {
        display: flex;
        flex-wrap: wrap;
    }
}

.work-project {
    position: relative;
    overflow: hidden;
    cursor: pointer;
}

@media screen and (min-width: 1024px) {
    .work-project {
        width: 50%;
    }
}

.work-project__thumb {
    display: block;
    width: 100%;
    transition: all .3s;
    transform: scale(1);
}

@media screen and (min-width: 1024px) {
    .work-project:hover .work-project__thumb {
        transform: scale(1.15);
    }
}

.work-project__overlay {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: #E81F76;
    color: #fff;
    justify-content: content;
    align-items: center;
    display: none;
    transition: all .3s;
}

@media screen and (min-width: 1024px) {
    .work-project__overlay {
        display: flex;
        opacity: 0;
        transform: scale(1.2);
        transform-origin: center center;
        padding: 80px;
    }

    .work-project:hover .work-project__overlay {
        opacity: 1;
        transform: scale(1);
    }
}

.work-project__heading {
    font-size: 4.4rem;
    line-height: 1.3;
    margin: 0;
}

.work-project__type {
    font-size: 2.4rem;
    margin: 0 0 .3em;
}

.work-project__role {
    font-size: 1.4rem;
    margin: 0;
    color: #000;
}

.work-anim-start {
    opacity: 0;
    transform: translate(0, 15px);
}

.work-anim-end {
    opacity: 1;
    transform: translate(0, 0);
}
