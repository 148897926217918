.content-arrow {
	display: block;
	text-decoration: none;
	margin: 0 0 14px;
	color: #000 !important;
	border-bottom: 0 !important;
}

.content-arrow svg {
	position: relative;
	top: -2px;
}

@media screen and (min-width: 1240px) {
	.content-arrow {
		position: fixed;
		display: block;
		left: 60px;
		top: 145px;
		white-space: nowrap;
		margin: 0;
	}
}
