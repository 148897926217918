.content {
	position: relative;
	display: block;
	margin: 100px auto 60px;
	max-width: 915px;
	padding: 0 20px;
}

@media screen and (min-width: 1024px) {
	.content {
		margin: 140px auto 100px;
	}
}

.content h1 {
	margin-top: 0;
	color: #000;
	font-weight: 700;
}

.content h2 {
	font-weight: 700;
	margin: 2.5em 0 .7em;
}

.content__subheading,
h2.content__subheading {
	color: #E81F76;
	font-weight: 700;
	font-size: 2.2rem;
	max-width: 530px;
	line-height: 1.35;
	margin: 1.7em 0;
}

@media screen and (min-width: 1024px) {
	.content__subheading,
	h2.content__subheading {
		font-size: 3.2rem;
	}
}

.content img {
	max-width: 100%;
	margin: 0 auto;
	height: auto;
}

.content ul {
	list-style-type: none;
	line-height: 1.8;
}

.content ul ul {
	font-size: .85em;
}

.content ul small {
	font-size: .7em;
}

.content a {
	color: #E81F76;
	display: inline-block;
	text-decoration: none;
	border-bottom: 1px dotted #E81F76;
}

.content .wp-caption {
	width: auto !important;
}

.link-arrow {
	position: relative;
	height: .8em !important;
	width: auto !important;
	margin: 0 0 0 10px !important;
	top: 2px;
}
