.brands {
    margin: 120px auto 0;
    width: 100%;
    padding: 0 20px;
}

@media screen and (min-width: 1024px) {
    .brands {
        display: flex;
        padding: 0 60px;
        margin: 150px auto 0;
    }
}

.brands-intro {
    margin: 0 0 40px;
}

@media screen and (min-width: 1024px) {
    .brands-intro {
        width: 350px;
        font-size: 2.5rem;
    }
}

@media screen and (min-width: 1300px) {
    .brands-intro {
        width: 500px;
        font-size: 2.7rem;
    }
}

.brands-logos {
    flex: 1;
    display: flex;
    flex-wrap: wrap;
}

@media screen and (min-width: 560px) {
    .brands-logos {
        margin: 50px 0 0;
    }
}

@media screen and (min-width: 1024px) {
    .brands-logos {
        margin: 0;
    }
}

@media screen and (min-width: 1400px) {
    .brands-logos {
        padding: 0 170px 0 0;
    }
}

.brands-logos__item {
    position: relative;
    width: 50%;
    height: 60px;
    margin: 0 0 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media screen and (min-width: 560px) {
    .brands-logos__item {
        height: 65px;
        margin: 0 0 40px;
    }
}

@media screen and (min-width: 1400px) {
    .brands-logos__item {
        height: 85px;
        margin: 0 0 60px;
    }
}

@media screen and (min-width: 1900px) {
    .brands-logos__item {
        width: 33.3%;
        height: 90px;
        margin: 0 0 120px;
    }
}

.brands-logos__item img {
    max-width: 110px;
    max-height: 100%;
}

@media screen and (min-width: 560px) {
    .brands-logos__item img {
        max-width: 150px;
    }
}

@media screen and (min-width: 1400px) {
    .brands-logos__item img {
        max-width: 194px;
    }
}

.brands-anim-start {
    opacity: 0;
    transform: translate(0, 25px);
}

.brands-anim-end {
    opacity: 1;
    transform: translate(0, 0);
}
