*,
*::before,
*::after {
	box-sizing: border-box;
}

html {
	font-size: 62.5%;
	height: 100%;
}

body {
	position: relative;
	font-family: 'Playfair Display', serif;
	font-size: 1.4rem;
	line-height: 1.59;
	background: #F4F4F4;
	padding: 0;
	margin: 0;
	color: #303030;
	scroll-behavior: smooth;
}

@media screen and (min-width: 1024px) {
	body {
		font-size: 1.9rem;
	}
}

p {
	margin: 0 0 1.5em;
}

@media screen and (min-width: 1024px) {
	p {
		margin: 0 0 1.7em;
	}
}

h1,
h2,
h3,
h4,
h5 {
	line-height: 1.1;
	font-weight: 400;
}

h1 {
	font-size: 3.4rem;
}

h1 small {
	font-size: .5em;
}

@media screen and (min-width: 768px) {
	h1 {
		font-size: 5.4rem;
	}
}

.main-logo__wrap {
	position: fixed;
	top: 0;
	left: 50%;
	transform: translate(-50%, 0);
	width: 100%;
	z-index: 99;
}

.main-logo {
	position: absolute;
	left: 20px;
	top: 20px;
	width: 124px;
	height: auto;
}

@media screen and (min-width: 1024px) {
	.main-logo {
		width: 200px;
		left: 60px;
		top: 40px;
	}
}

.main-logo__wrap {
	transition: opacity 1s;
}

.main-logo__wrap.hide {
	opacity: 0;
	transition: none;
	pointer-events: none;
}

.btn,
button[type=submit] {
	display: inline-block;
	border: 0;
	background: #000;
	color: #fff;
	font-size: 2rem;
	padding: .7em 1.5em;
	cursor: pointer;
	font-family: inherit;
}

.btn:disabled {
	background: grey;
	cursor: not-allowed;
}

/* Forms */

form {
	max-width: 600px;
}

input[type=text],
input[type=number],
input[type=email],
input[type=tel],
textarea {
	display: block;
	width: 100%;
	border: 1px solid #afafaf;
	font-size: 2rem;
	padding: 1em;
}

input[type=number] {
	max-width: 100px;
}

input[type=text]:focus,
input[type=number]:focus,
input[type=email]:focus,
input[type=tel]:focus,
textarea:focus {
	border: 1px solid #E81F76;
	outline: none;
}

label {
	color: #000;
	/* text-transform: uppercase; */
	font-size: 1.6rem;
}

label {
	display: block;
}

.input-wrap {
	position: relative;
	margin: 0 0 1.7em;
}

.input-desc {
	font-size: 1.4rem;
	margin: 0 0 4px;
}
