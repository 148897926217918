.columns {
	position: relative;
	width: 100vw;
	height: 100vh;
	background-color: #141414;
	background-size: cover;
	background-position: center center;
	overflow: hidden;
}

.columns__bg-video {
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	min-width: 100%;
	min-height: 100%;
	width: auto;
	height: auto;
}

.columns-items {
	position: relative;
	display: flex;
	flex-direction: column;
	height: 100%;
	background: rgba(0, 0, 0, .4);
}

@media screen and (min-width: 1024px) {
	.columns-items {
		flex-direction: row;
	}
}

.columns-item {
	height: calc(33.333% - 40px);
	background: transparent;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	padding: 0 80px;
	transition-property: opacity;
	cursor: pointer;
	text-decoration: none;
	background: rgba(46, 46, 46, .5);
}

.columns-item:first-child {
	height: calc(33.333% + 80px);
	padding: 100px 80px 0;
}

@media screen and (min-width: 1024px) {
	.columns-item {
		height: 100%;
		width: 33.333%;
		display: block;
		position: relative;
	}

	.columns-item:first-child {
		height: 100%;
		padding: 0 80px;
	}

	.columns-item:hover {
		background: rgb(232, 31, 118) !important;
	}
}

.column__gfx {
	position: absolute;
	display: block;
	left: 50%;
	bottom: calc(100% + 5px);
	transform: translate(-50%, 0);
	width: auto;
	height: 40px;
	transition: height .2s;
}

@media screen and (min-width: 1024px) {
	.column__gfx {
		height: 80px;
		bottom: calc(100% + 15px);
	}
}

@media screen and (min-width: 1024px) {
	.column__gfx {
		bottom: calc(100% + 30px);
	}

	.no-touch .columns-item:hover .column__gfx {
		height: 140px;
	}
}

.column__heading {
	position: relative;
	font-size: 2.2rem;
	font-weight: 400;
	line-height: 1.4;
	margin: 0 0 1em;
}

.column__heading .pt1 {
	display: none;
	position: absolute;
	left: 50%;
	top: 0;
	transform: translate(-50%, 0);
	transition: opacity .2s;
}

.column__heading .pt2 {
	transition: opacity .2s;
	opacity: 1;
}

@media screen and (min-width: 1024px) {
	.column__heading {
		font-size: 3.4rem;
	}

	.no-touch .column__heading .pt1 {
		opacity: 1;
		display: block;
	}

	.no-touch .column__heading .pt2 {
		opacity: 0;
	}

	.no-touch .columns-item:hover .column__heading .pt1 {
		opacity: 0;
	}

	.no-touch .columns-item:hover .column__heading .pt2 {
		opacity: 1;
	}
}

.column__desc {
	font-size: 1.8rem;
	display: none;
}

@media screen and (min-width: 1024px) {
	.column__desc {
		font-size: 2rem;
	}

	.no-touch .column__desc {
		display: block;
	}

	.no-touch .column__desc,
	.no-touch .column__cta {
		opacity: 0;
		transition: all .2s;
		transform: translate(0, 20px);
	}

	.no-touch .columns-item:hover .column__desc,
	.no-touch .columns-item:hover .column__cta {
		opacity: 1;
		transform: translate(0, 0);
	}

	.no-touch .columns-item:hover .column__cta {
		transition-delay: .13s;
	}
}

.column__cta {
	font-size: 1.8rem;
	color: #000;
	display: none;
}

@media screen and (min-width: 1024px) {
	.column__cta {
		font-size: 2.2rem;
	}

	.no-touch .column__cta {
		color: #000;
		display: block;
	}
}

.column__cta span {
	display: inline-block;
	padding: 0 0 .2em;
	border-bottom: 1px dotted #000;
}

.column__cta img {
	position: relative;
	display: inline-block;
	width: auto;
	height: .8em;
	margin: 0 0 0 .3em;
	top: 2px;
}

.columns-item__inner {
	color: #fff;
	width: 100%;
	max-width: 340px;
}

@media screen and (min-width: 1024px) {
	.columns-item__inner {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, 0);
	}

	.no-touch .columns-item__inner {
		transform: translate(-50%, -40px);
	}
}

.columns-column-anim-start {
	opacity: 0;
}

.columns-column-anim-end {
	opacity: 1;
}

.columns-cta {
	position: relative;
	display: inline-block;
	border-radius: 50%;
	width: 35px;
	height: 35px;
	border: 1px solid #fff;
}

.columns-cta img {
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	width: 40%;
}

.columns-sub-content {
	width: 100vw;
	height: 100vh;
	overflow: auto;
}

.columns-sub-content-wrap {
	width: 100vw;
	height: 100vh;
}
