.gradient-bar {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 110px;
    z-index: 1;
    pointer-events: none;
}

@media screen and (min-width: 1024px) {
    .gradient-bar {
        height: 160px;
    }
}

.test {
	position: absolute;
    left: 200px;
    top: 200px;
    width: 50px;
    height: 50px;
    background: blue;
}
