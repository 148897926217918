.blog {
	margin: 110px 20px;
	max-width: 922px;
}

@media screen and (min-width: 1032px) {
	.blog {
		margin: 150px auto;
	}
}

.blog h1 {
	font-weight: 700;
}

@media screen and (min-width: 768px) {
	.blog h1 {
		margin: 0 0 1.3em;
	}
}

@media screen and (min-width: 768px) {
	.blog__items {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		grid-auto-rows: 1fr;
		grid-column-gap: 40px;
		grid-row-gap: 40px;
	}
}

.blog__item {
	display: flex;
	flex-direction: column;
	color: #000;
	text-decoration: none;
	margin: 0 0 25px;
}

@media screen and (min-width: 768px) {
	.blog__item {
		margin: 0;
	}
}

.blog__details {
	padding: 15px;
	background: #fff;
	transition: background .3s;
}

@media screen and (min-width: 768px) {
	.blog__details {
		flex: 1;
		display: flex;
		flex-direction: column;
		min-height: 265px
	}
}

.blog__item:hover .blog__details {
	background: #E81F76;
	color: #fff;
}

.blog__item:hover .blog__details * {
	color: #fff;
}

.blog__thumb {
	display: block;
	width: 100%;
	margin: 0;
}

.blog__title {
	font-size: 2.4rem;
	margin: 0 0 0.1em;
	line-height: 1.2;
}

@media screen and (min-width: 768px) {
	.blog__title {
		font-size: 3.3rem;
		margin: 0 0 0.25em;
	}
}

.blog__date {
	font-size: 1rem;
	color: #4C4C4C;
}

@media screen and (min-width: 768px) {
	.blog__date {
		font-size: 1.3rem;
		margin: 0 0 1em;
	}
}

.blog__desc {
	font-size: 1.2rem;
	color: #303030;
}

@media screen and (min-width: 768px) {
	.blog__desc {
		font-size: 1.5rem;
		margin: 0 0 1em;
	}
}

.blog__tags {
	font-size: 1.3rem;
	color: #E81F76;
	margin: 3em 0 0;
	font-family: 'Source Sans Pro', sans-serif;
}

@media screen and (min-width: 768px) {
	.blog__tags {
		margin: auto 0 0;
	}
}

.blog__tag:last-child .pipe {
	display: none;
}

.post h1 {
	font-weight: 400;
	margin: 0 0 .25em;
}

.post__hero {
	display: block;
	width: 100%;
	margin: 0 auto -60px;
}

.post__hero.desktop {
	display: none;
}

@media screen and (min-width: 768px) {
	.post__hero.mobile {
		display: none;
	}

	.post__hero.desktop {
		display: block;
	}
}

@media screen and (min-width: 1024px) {
	.post__hero {
		margin: 0 auto -70px;
	}
}

.post__date {
	color: #4C4C4C;
	font-size: 1.3rem;
	margin: 0 0 2em;
}

@media screen and (min-width: 768px) {
	.post__date {
		font-size: 1.6rem;
		margin: 0 0 2em;
	}
}

.post__author {
	color: #000;
	font-size: 1.3rem;
	margin: 1.3em 0 -.1em;
}

@media screen and (min-width: 768px) {
	.post__author {
		font-size: 2rem;
	}
}

.post__tags {
	margin: 3em 0 0;
	font-family: 'Source Sans Pro', sans-serif;
	line-height: 2.2;
}

.post__tag {
	line-height: 1;
	white-space: nowrap;
	border: 1px solid #E81F76;
	color: #E81F76;
	padding: .3em .5em;
	font-size: 1.3rem;
	margin: 0 10px 0 0;
	display: inline-block;
}

@media screen and (min-width: 768px) {
	.post__tag {
		font-size: 2.2rem;
	}
}

.rec {
	background: #303030;
	color: #fff;
	padding: 1em 0;
}

@media screen and (min-width: 660px) {
	.rec {
		padding: 3.5em 0;
	}
}

.rec h2 {
	margin: 0 0 1.5em;
	font-size: 2.6rem;
	font-weight: 700;
}

@media screen and (min-width: 660px) {
	.rec h2 {
		font-size: 3.2rem;
	}
}

.rec__inner {
	max-width: 915px;
	padding: 0 20px;
	margin: 0 auto;
}

.rec__cards {
	
}

@media screen and (min-width: 660px) {
	.rec__cards {
		display: grid;
		grid-column-gap: 20px;
		grid-row-gap: 20px;
		grid-template-columns: repeat(2, 1fr);
		grid-auto-rows: 1fr;
	}
}

@media screen and (min-width: 900px) {
	.rec__cards {
		grid-template-columns: repeat(3, 1fr);
	}

	.rec__cards .blog__title {
		font-size: 2.6rem;
	}

	.rec__cards .blog__desc {
		font-size: 1.3rem;
	}
}