.loader {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: #F4F4F4;
    pointer-events: none;
    opacity: 0;
    transition: opacity .2s;
}

.loader.show {
    pointer-events: auto;
    opacity: 1;
    transition: none;
}

.loader__ring {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 110px;
    height: 110px;
    opacity: 0;
    border: 5px solid #787878;
    border-radius: 50%;
    animation-name: rings;
    animation-duration: 3s;
    animation-iteration-count: infinite;
}

.loader__ring:nth-child(2) {
    animation-delay: 1.5s;
}

@keyframes rings {
    from {
        width: 140px;
        height: 140px;
        opacity: 1;
    }

    to {
        width: 624px;
        height: 624px;
        opacity: 0;
    }
}

.loader__logo {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 55px;
    height: auto;
    animation-name: h;
    animation-duration: 1.5s;
    animation-iteration-count: infinite;
    animation-delay: 0.8s;
}

@keyframes h {
    0% {
        transform: translate(-50%, -50%) scale(1);
    }

    20% {
        transform: translate(-50%, -50%) scale(1.2);
        animation-timing-function: ease-out;
    }

    40% {
        transform: translate(-50%, -50%) scale(1);
        animation-timing-function: ease-in;
    }
}
