.two-slides {
    position: relative;
    width: 100%;
    height: 100vh;
    overflow: hidden;
}

.two-slides__screens {
    position: relative;
    width: 200%;
    height: 100%;
    transform: translate3D(0, 0, 0);
    display: flex;
    transition: all .55s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.two-slides--right .two-slides__screens {
    transform: translate3D(-50%, 0, 0);
}

.two-slides__screen {
    position: relative;
    width: 50%;
    height: 100%;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    /* create new stacking context for fixed position elements */
    transform: translate(0, 0);
}
